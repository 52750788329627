import { Link } from 'react-router-dom';
import '../../../index.css';
import './searchBar.css'

function SearchBar() {
  return (
    // <div>searchBar</div>
    <Link to="/search">
      <label className="searchBar flex align-center justify-center input">
      <span className="material-icons icon-64">search</span>
      <input type="text" className="raleway subhead2" placeholder='Search for Cars!' />
    </label>
    </Link>
    // <label className="searchBar flex align-center justify-center input">
    //   <span class="material-icons icon-64">search</span>
    //   <input type="text" className="raleway subhead2" placeholder='Search for Cars!' />
    // </label>
  )
}

export default SearchBar