import '../../pages/about/about.css'
import Goals from './goals.jsx'

function OurGoals() {
  return (
    <div className="ourgoals flex flex-column">

      <h1 className='cabin heading'>Our Goals</h1>

      <Goals />

    </div>
  )
}

export default OurGoals