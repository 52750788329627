import '../../index.css'
import './cardetails.css'
import Cars from '../../assets/database/cars.json'
import { useParams } from 'react-router-dom'

function CarDetails() {
  const { id } = useParams()
  // eslint-disable-next-line
  const car = Cars.filter(car => car.id == id) 
  console.log(car)

  return (
    <>
      {car.map((car) => (
        <div className="container car-details flex flex-column" key={ car.id }>

          {/* <div>
            <h1>{ id }</h1>
          </div>

          <div>
            <h1>{ car.id }</h1>
          </div> */}

          <div className="head flex flex-column align-center justify-center">
            <h2 className="car-title subhead2 cabin">{ car.name }</h2>
            <div className='head-row flex flex-row'>
              <h3 className="car-price subhead3 raleway">{ car.price }</h3>
              <h3 className="car-mileage subhead3 raleway">{ car.mileage }</h3>
            </div>
          </div>

          <div className="images flex flex-row align-center justify-center">
            <img src={ car.pictures.left } alt="" className="left img-paper" />
            <img src={ car.pictures.front } alt="" className="front img-paper" />
            <img src={ car.pictures.right } alt="" className="right img-paper" />
          </div>

          <div className="details flex flex-row align-center justify-center">
            <div className="overview paper cabin flex flex-column align-center justify-center">
              <h2 className='subhead3 cabin'>Overview</h2>
              <div className='text-center text-card-space flex flex-column'>
                <h3 className="car-body subhead4 raleway">Doors - { car.overview.doors }</h3>
                <h3 className="car-color subhead4 raleway">Drive - { car.overview.drive }</h3>
                <h3 className="car-make subhead4 raleway">Transmission - { car.overview.transmission }</h3>
                <h3 className="car-model subhead4 raleway">Seating - { car.overview.seating }</h3>
                <h3 className="car-trim subhead4 raleway">Fuel Economy - { car.overview.fueleconomy }</h3>
                <h3 className="car-year subhead4 raleway">Fuel Compacity - { car.overview.fuelcompacity }</h3>
              </div>
              {/* <div>
                <h3 className="car-body subhead4 raleway">{ car.body }</h3>
                <h3 className="car-color subhead4 raleway">{ car.color }</h3>
                <h3 className="car-make subhead4 raleway">{ car.make }</h3>
                <h3 className="car-model subhead4 raleway">{ car.model }</h3>
                <h3 className="car-trim subhead4 raleway">{ car.trim }</h3>
                <h3 className="car-year subhead4 raleway">{ car.year }</h3>
                <h3 className="car-fuel subhead4 raleway">{ car.fuel }</h3>
                <h3 className="car-mileage subhead4 raleway">{ car.mileage }</h3>
              </div> */}
            </div>
            <div className="features paper cabin flex flex-column align-center justify-center">
            <h2 className='subhead3 cabin'>Features</h2>
              <div className='text-center text-card-space flex flex-column'>
                <div className='subhead4 raleway'>{ car.features.feature1 }</div>
                <div className='subhead4 raleway'>{ car.features.feature2 }</div>
                <div className='subhead4 raleway'>{ car.features.feature3 }</div>
                <div className='subhead4 raleway'>{ car.features.feature4 }</div>
                <div className='subhead4 raleway'>{ car.features.feature5 }</div>
                <div className='subhead4 raleway'>{ car.features.feature6 }</div>
              </div>
            </div>
          </div>

        </div>
      ))}
    </>

    // <div className="container car-details flex flex-column">
    //   <div>
    //     <h1>{ id }</h1>
    //   </div>

    //   <div>
    //     <h1>{ car.id }</h1>
    //   </div>

    //   <div className="head flex flex-column align-center justify-center">
    //     <h2 className="car-title subhead2 cabin">Title</h2>
    //     <div className='head-row flex flex-row'>
    //       <h3 className="car-price subhead3 raleway">Price</h3>
    //       <h3 className="car-mileage subhead3 raleway">Miles</h3>
    //     </div>
    //   </div>

    //   <div className="images flex flex-row align-center justify-center">
    //     <img src={ Left } alt="" className="left img-paper" />
    //     <img src={ Front } alt="" className="front img-paper" />
    //     <img src={ Right } alt="" className="right img-paper" />
    //   </div>

    //   <div className="details flex flex-row align-center justify-center">
    //     <div className="overview paper cabin flex flex-column align-center justify-center">
    //       <h2 className='subhead3 cabin'>Overview</h2>
    //       <div>
    //         <h3 className="car-body subhead4 raleway">Body</h3>
    //         <h3 className="car-make subhead4 raleway">Make</h3>
    //         <h3 className="car-model subhead4 raleway">Model</h3>
    //         <h3 className="car-trim subhead4 raleway">Trim</h3>
    //         <h3 className="car-year subhead4 raleway">Year</h3>
    //         <h3 className="car-fuel subhead4 raleway">Fuel</h3>
    //         <h3 className="car-mileage subhead4 raleway">Miles</h3>
    //       </div>
    //     </div>
    //     <div className="features paper cabin flex flex-column align-center justify-center">
    //     <h2 className='subhead3 cabin'>Features</h2>
    //       <div>
    //         <div>Info</div>
    //         <div>Info</div>
    //         <div>Info</div>
    //         <div>Info</div>
    //         <div>Info</div>
    //         <div>Info</div>
    //         <div>Info</div>
    //         <div>Info</div>
    //         <div>Info</div>
    //         <div>Info</div>
    //       </div>
    //     </div>
    //   </div>

    // </div>
  )
}

export default CarDetails