import '../../index.css';
import './home.css';
import Hero from '../../components/home/hero';
import SaleRep from '../../components/home/salerep';
import Search from '../../components/home/search';
import Finance from '../../components/home/finance';

function Home() {
  return (
    <div>
      <div className="container flex flex-column">
        <Hero />
        <Search />
        <Finance />
        <SaleRep />
      </div>
    </div>
  )
}

export default Home