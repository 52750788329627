import '../../index.css';
import './NoPage.css'

function Nopage() {
  return (
    <div className='nopage flex flex-column align-center justify-center'>
      <h1 className='cabin section'>404</h1>
      <h3 className='raleway'>Page not Found</h3>
    </div>
  )
}

export default Nopage