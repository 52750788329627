import { Outlet } from "react-router-dom";
import Footer from "../components/common/footer/footer";
import Navigation from "../components/common/navigation/navigation";

const Layout = () => {
  return (
    <>
      <div>
        <Navigation />
        <Outlet />
        <Footer />
      </div>
    </>
  )
};

export default Layout;