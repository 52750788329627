import '../../pages/about/about.css'
import Logo from '../../assets/images/brand/logo.svg';

function AboutTeam() {
  return (
    <div className="about-team flex flex-wrap flex-row align-center justify-center">

      <div>
        <img src={ Logo } alt="" className='about-image' />
      </div>

      <div className='about-header text-center flex flex-column'>
        <h2 className='paper-text cabin subhead1'>
          Learn more about our <span className='text-primary'>Team, Igene!
        </span></h2>
        {/* <h4 className='cabin subhead4'>Scroll down to learn more!</h4> */}
      </div>

    </div>
  )
}

export default AboutTeam