import '../../pages/home/home.css'
import imagerep1 from '../../assets/images/people/bob.webp'

function SaleRep() {
  return (
    // <div>salerep</div>
    <div className='section salerep'>
      <h2 className='cabin heading'>Contact our Sale Representative!</h2>
      <div className='flex space-around align-center salerep-container'>
        <form className='flex flex-column input-container'>

          <label className="emailBar flex input">
            <span className="material-icons icon-64">alternate_email</span>
            <input id='emailInputs' type="email" className="raleway subhead2" placeholder='Email' />
          </label>

          <div className='flex div'>
            <label className="nameBar flex input">
              <span className="material-icons-outline icon-64">badge</span>
              <input id='emailInputs' type="text" className="raleway subhead2" placeholder='Name' />
            </label>

            <button type='reset' className='button'>Submit</button>
          </div>
        </form>

        <div className="flex align-center justify-center">
          <img src={ imagerep1 } alt="" className="sale-image" />
        </div>
      </div>
    </div>
  )
}

export default SaleRep