import '../../pages/home/home.css'

function Hero() {
  return (
    // <div>hero</div>
    <div className="hero flex align-center justify-center">
        <h1 className='cabin heading'>Find your forever Car!</h1>
    </div>
  )
}

export default Hero