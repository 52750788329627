import '../../index.css';
import AboutTeam from '../../components/about/aboutteam'
import OurGoals from '../../components/about/ourgoals'

function About() {
  return (
    <div>
      <div className="container flex flex-column">
        <AboutTeam />
        <OurGoals />
      </div>
    </div>
  )
}

export default About