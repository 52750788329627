import React from 'react'
import GoalJSON from './goals.json'

function Goals() {
  return (
    // <div className="goal-card paper-goal">
    //     <h1 className='cabin subhead2'>Our Goals</h1>
    //     <p className='raleway subhead4'>Goal Description</p>
    // </div>

    <div className="goal-container flex flex-wrap align-center justify-center">
      {GoalJSON.map((goal) => (
        <div className="goal-card paper-goal flex flex-column" key={ goal.id }>
            <h1 className='cabin subhead2'>{ goal.title }</h1>
            <p className='raleway subhead4'>{ goal.description }</p>
        </div>
      ))}
    </div>
  )
}

export default Goals