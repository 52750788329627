// import { Link } from "react-router-dom";
import '../../../index.css';
import "../footer/footer.css"
import Logo from '../../../assets/images/brand/logo.svg';

function Footer() {
  return (
    <footer className="footer">
      <div className="container">

        <div className="footer-main">
          <div className="footer-bin raleway subhead2 flex space-between">

            <div className="flex flex-column gap-y">
              <ul>
                <li><h4>Financing</h4></li>
                <li><h5>How it Works</h5></li>
                <li><h5>Auto Finance</h5></li>
              </ul>

              <ul>
                <li><h4>Certification</h4></li>
                <li><h5>Certified Cars</h5></li>
                <li><h5>Certified Sellers</h5></li>
              </ul>
            </div>

            <ul>
              <li><h4>How We Work</h4></li>
              <li><h5>Buying from Us</h5></li>
              <li><h5>Protection Plans</h5></li>
              <li><h5>Igene Insurance</h5></li>
            </ul>

            <ul>
              <li><h4>About Igene</h4></li>
              <li><h5>Foundation</h5></li>
              <li><h5>Media Center</h5></li>
              <li><h5>Partnerships</h5></li>
              <li><h5>Locations</h5></li>
            </ul>

            <div className='flex flex-column'>
              <div className="footer-logo-container">
                <img src={ Logo } alt="OWL" className="logo" />
              </div>
              <ul className='text-center'>
                <li><h3>Support</h3></li>
                <li><h3>Media</h3></li>
                <li><h3>Partnership</h3></li>
                <li><h3>Investor</h3></li>
              </ul>
            </div>

          </div>
        </div>

        <div className="footer-bottom cabin subhead4 flex flex-column align-center bottom-container">
          <div>
            <ul className="flex text-gap">
              <li><h3>Search Cars</h3></li>
              <li><h3>Sitemap</h3></li>
            </ul>
          </div>

          <div>
            <h3>Copyright @ 2023 Igene OWL</h3>
          </div>

          <div>
            <ul className="flex text-gap">
              <li><h3>User Privacy Policy</h3></li>
              <li><h3>Financial Privacy Policy</h3></li>
              <li><h3>Do Not Sell My Info</h3></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer