import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import './icons.css';
import Layout from './pages/Layout';
import Home from './pages/home/Home';
import Nopage from './pages/NoPage/Nopage';
import Search from './pages/search/Search';
import About from './pages/about/About';
// import Finance from './pages/finance/Finance';
import CarDetails from './pages/car-details/cardetails';


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/search" element={<Search />} />
          {/* <Route path="/finance" element={<Finance />} /> */}
          <Route path="/about" element={<About />} />
          {/* <Route path="/cars/details" element={<CarDetails />} /> */}
          <Route path="/cars/details/:id" element={<CarDetails />} />
          <Route path="*" element={<Nopage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);