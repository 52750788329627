import '../../../index.css';
import './card.css';
// import image from '../../../assets/images/brand/logo.svg';
import { Link } from 'react-router-dom';

function Card({data}) {
  return (
    
    <div className='card-container flex align-center justify-center'>
      {data.map((car) => (
        <Link to={`/cars/details/${ car.id }`} data={ car } key={ car.id } >
          <div className="card flex">

              {/* <div>
                <h1>{ car.id }</h1>
              </div> */}
              
              <div className="card-image">
                <img src={ car.pictures.avatar } alt="car" className="car-profile" />
              </div>

              <div className="card-content flex flex-column">

                <div className="card-title cabin">
                  <h2>{ car.name }</h2>
                </div>
                <div className="card-text raleway">
                  <h3>{ car.color }</h3>
                </div>
                
                <div className='card-bottom flex flex-row'>
                  <div className="card-text raleway">
                    <h3>{ car.price }</h3>
                  </div>

                  <div className="card-text raleway">
                    <h3>{ car.mileage }</h3>
                  </div>
                </div>

              </div>

          </div>
        </Link>
        // <div className="card flex">

        //   <div>
        //     <h1>{ car.id }</h1>
        //   </div>
          
        //   <div className="card-image">
        //     <img src={ image } alt="car" className="car-profile" />
        //   </div>

        //   <div className="card-content flex flex-column">

        //     <div className="card-title cabin">
        //       <h2>{ car.name }</h2>
        //     </div>
        //     <div className="card-text raleway">
        //       <h3>{ car.color }</h3>
        //     </div>
            
        //     <div className='card-bottom flex flex-row'>
        //       <div className="card-text raleway">
        //         <h3>{ car.price }</h3>
        //       </div>

        //       <div className="card-text raleway">
        //         <h3>{ car.mileage }</h3>
        //       </div>
        //     </div>

        //   </div>

        // </div>
      ))}
    </div>

    // <div>
    //   {data.map((car) => (
    //     <div className="card flex">
            
    //       <div className="card-image">
    //         <img src={ image } alt="car" className="car-profile" />
    //       </div>

    //       <div className="card-content flex flex-column justify-center">


    //         <div className="card-title cabin">
    //           <h2>{ car.name }</h2>
    //         </div>

    //         <div className="card-text cabin">
    //           <h3>{ car.color }</h3>
    //         </div>
            
    //         <div className='card-bottom flex flex-row'>
    //           <div className="card-text raleway">
    //             <h3>{ car.price }</h3>
    //           </div>

    //           <div className="card-text raleway">
    //             <h3>{ car.mileage }</h3>
    //           </div>
    //       </div>
    //     </div>
    //   ))}
    // </div>

  )
}

export default Card