import React from 'react'

function Finance() {
  return (
    <div className='finance flex flex-column'>

      <h2 className='cabin heading'>Need help with Financing?</h2>

        <div className='div flex flex-column align-center justify-center'>

            <form className='finance-button'>
                <button formAction='https://www.calculator.net/auto-loan-calculator.html' className='button'>
                  Car Loan Calculator
                </button>
            </form>

        </div>

    </div>
  )
}

export default Finance