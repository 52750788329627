import { Link } from "react-router-dom";
import '../../../index.css';
import './navigation.css';
import Logo from '../../../assets/images/brand/logo.svg';



function Navigation() {

  function showLinks() {
    var nav = document.getElementById("nav-panel");

    if (nav.className === "nav-panel") {
      nav.className += " show";
    } else {
      nav.className = "nav-panel";
    }
  }

  return (
    <>
      <nav className="navigation">
        <div className="container flex space-between align-center">
          <div className="logo-container">

          <Link to="/">
              <img src={ Logo } alt="OWL" className="logo" />
          </Link>
            
          </div>

          <ul className="flex nav-container subhead3">

            <li>
              <Link className="nav-icon nav-links" onClick={showLinks}>
                <span className="material-icons-outlined icon-32">menu</span>
              </Link>
            </li>

            <li className="nav-items">
              <Link to="/">Home</Link>
            </li>
            <li className="nav-items">
              <Link to="/search">Search</Link>
            </li>
            {/* <li className="nav-items">
              <Link to="/finance">Financing</Link>
            </li> */}
            <li className="nav-items">
              <Link to="/about">About Us</Link>
            </li>
            {/* <li className="nav-items">
              <Link to="/invaild">Invaild</Link>
            </li> */}

          </ul>

        </div>

        <div className="container align-center subhead3">

          <ul className="nav-panel" id="nav-panel">

            <li className="nav-items mobile">
              <Link to="/">Home</Link>
            </li>
            <li className="nav-items mobile">
              <Link to="/search">Search</Link>
            </li>
            {/* <li className="nav-items mobile">
              <Link to="/finance">Financing</Link>
            </li> */}
            <li className="nav-items mobile">
              <Link to="/about">About Us</Link>
            </li>
            {/* <li className="nav-items mobile">
              <Link to="/invaild">Invaild</Link>
            </li> */}

          </ul>

        </div>

      </nav>
    </>
  )
}

export default Navigation