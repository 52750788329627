import '../../pages/home/home.css'
import SearchBar from '../../components/common/searchBar/searchBar';
import { Link } from 'react-router-dom';

function Search() {
  return (
    // <div>search</div>
    <>
    <div className="bin flex align-center justify-center">
      <SearchBar />
    </div>

    <div className="bin searchBin flex align-center justify-center">

      <Link to="/search">
        <div className="searchCard flex">
          <span className="material-icons icon-128">approval</span>
          <div className="text raleway">
            <h3 className='subhead1'>Brands</h3>
            <h5 className='subhead3'>Search by Brand</h5>
          </div>
        </div>
      </Link>

      <Link to="/search">
        <div className="searchCard flex">
          <span className="material-icons-outline icon-128">directions_car</span>
          <div className="text raleway">
            <h3 className='subhead1'>Model</h3>
            <h5 className='subhead3'>Search by Model</h5>
          </div>
        </div>
      </Link>

      <Link to="/search">
        <div className="searchCard flex">
          <span className="material-icons-outline icon-128">calendar_month</span>
          <div className="text raleway">
            <h3 className='subhead1'>Years</h3>
            <h5 className='subhead3'>Search by Year</h5>
          </div>
        </div>
      </Link>

      <Link to="/search">
        <div className="searchCard flex">
          <span className="material-icons-outline icon-128">format_paint</span>
          <div className="text raleway">
            <h3 className='subhead1'>Color</h3>
            <h5 className='subhead3'>Search by Color</h5>
          </div>
        </div>
      </Link>
    </div>
    </>
  )
}

export default Search