import '../../index.css';
import './search.css';
import Card from '../../components/common/card/card';
import Cars from '../../assets/database/cars.json';
import { useState } from 'react';

function Search() {
  const [query, setQuery] = useState("");
  const keys = ["name", "price", "color", "mileage"];
  const search = (data) => {
    return data.filter((item) =>
      keys.some((key) => item[key].toLowerCase().includes(query))
    );
  };

  // const carsList = Cars.map((cars) => {
  //   return (
  //     <Card data={search(Cars)}
  //       id = { cars.id }
  //       name = { cars.name }
  //       price = { cars.price }
  //       color = { cars.color }
  //       mileage = { cars.mileage }
  //     />
  //   )
  // })

  return (
    <div className="container search">
      
      <label className="searchBar flex align-center input">
      <span className="material-icons icon-64">search</span>
      <input type="text" className="raleway subhead2" placeholder='Search for Cars!' onChange={(e) => setQuery(e.target.value.toLowerCase())} />
      </label>

      <div className="cards">
        <Card data={search(Cars)} />
      </div>
    </div>
  )
}

export default Search